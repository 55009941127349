.wrapper {
  padding: 25px 30px 20px 30px;
  border-top: 1px solid #DFDFDF;
}

.title {
  padding-bottom: 5px;
  font-weight: bold;
  font-size: 12px;
  color: #666666;
}

.list {
  padding-left: 20px;
  list-style-type: none;
  font-size: 12px;
}

.option {
  line-height: 3em;
  text-decoration: none;
  color: #737373;
  font-weight: medium;
}

.active {
  font-weight: bold;
  color: var(--vbop-main-colour);
}