.tableWrapper
{
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  overflow-x: auto;
}

.table
{
  width: 100%;
  padding: 5px;
  font-size: small;
}

  .table tbody tr
  {
    line-height: 1.5em;
    min-height: 30px;
  }

td
{
  border-bottom: 1px solid #ddd;
  text-align: left;
  padding: 5px 12px;
}

.flag
{
  width: 20px;
}

.greyBg
{
  background: #F5F5F5;
}

.leftMargin
{
  margin-left:2px;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .mobileHidden {
    display: none;
  }
}


.orderStatus {
  border-radius: 1px;
  background: #E9E9E9;
  padding: 2px;
  width: 90px;
  height: 20px;
  color: white;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  font-size: x-small;
}

.received {
  background-color: #68C7EC;
}

.awaitingApproval {
  background-color: #F4D161;
}

.awaitingApproval2 {
  background-color: #F4D161;
}

.processing {
  background-color: #F3B86D;
}

.partiallyDespatched {
  background-color: #B9A7D4;
}

.despatched {
  background-color: #AB8DDD;
}

.cancelled {
  background-color: #A8A8A8;
}

.returned {
  background-color: #F36D6D;
}
