.addContainer {
  padding-left: 2.0em;
}

.addText {
  font-weight: bold;
  color: #B4B4B4;
  font-size: 1.2em;
  width: auto;
  display: inline-block;
  padding: 0.1em;
}

.addText:hover {
  color: #0097EC;
  cursor: pointer;
}
