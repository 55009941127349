@import url("//netdna.bootstrapcdn.com/bootstrap/3.0.0/css/bootstrap-glyphicons.css");

:root {
  /* header */
  --header-height: 5em;
  --header-height-mobile: var(--header-height);

  /* Sidebar */
  --sidebar-width: 18em;
  --sidebar-width-mobile: var(--sidebar-width);

  --vbop-main-colour:#117440;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Lato', sans-serif;
  background-color: #FAFAFA;
}

.align-content-right {
  text-align: right;
}

.text-align-center {
  text-align: center;
}
