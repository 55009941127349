.panel {
  width: 100%;
}

.scrollable {
  overflow-y: scroll;
}

/* Themes */
.default {
  background-color: white;
  border: 1px solid #EEEEEE;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}

.grey {
  background-color: #F8F8F8;
  border: 1px solid #D2D2D2;
}
