.tabs {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  margin-top: 45px;
}

.tab {
  font-weight: bold;
  color:#737373;
  background-color: #ECECEC;
  border:none;
}

.tab:hover {
  cursor: pointer;
}

.active {
  background-color:#FAFAFA;
  cursor: default;
}

.small {
  padding: 0.3em 2.5em;
}

.medium {
  padding: 0.7em 5em;
}

.large {
  padding: 1em 7em;
}
