.input {
  width: 100%;
  height: 40px;
  margin: 0;
  padding: 0 20px;
  background: #f8f8f8;
  border: 2px solid #ddd;
  font-size: 14px;
  font-weight: 300;
  line-height: 40px;
  color: #888;
  border-radius: 0px;
  box-shadow: none;
  transition: all .3s;
  display: block;
}

.button {
  width: 100%;
  border: 0;
  font-size: 16px;
  font-weight: 300;
  line-height: 40px;
  height: 40px;
  margin: 0;
  padding: 0 20px;
  vertical-align: middle;
  background:#4aaf51;
  color: #fff;
  border-radius: 0px;
  text-shadow: none;
  transition: all .3s;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
}

.button:hover {
  border: 0;
  opacity: 0.5;
  color: #fff;
}


.formHeader {
  background-color: white;
  padding: 25px 25px 30px 25px;
}

.formBody {
  background-color: #eee;
  padding: 25px 25px 30px 25px;
}

.errorMessage {
  color: red;
  margin: 5px 0px;
}
