.flag
{
  width: 20px;
}

.greyBg
{
  background: #F5F5F5;
}

.leftMargin
{
  margin-left:2px;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .mobileHidden {
    display: none;
  }
}
