.wrapper {
  width: 100%;
}

.title {
  margin-left: 1em;
}

.horizontalRule {
  margin-top: 0;
}
