.table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

th {
  padding: 10px 12px;
  text-align: left;
  font-weight: bold;
  border-bottom: 1px solid #656565;
}

td {
  border-bottom: 1px solid#ddd;
  text-align: left;
  padding: 5px 12px;
}

.light th {
  border-bottom: 1px solid #E3E3E3;
  padding: 10px 12px 4px 12px;
}

.light td {
  border-bottom: none;
}

.disabledRow {
  background-color: rgba(0, 0, 0, 0.1);
  color: grey;
}

.errorRow {
  background-color: rgba(248, 36, 36, 0.381);
}

