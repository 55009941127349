/* default */
.default {
  text-shadow: 0 1px 0 #fff;
  box-shadow: inset 0 1px 0 rgba(255,255,255,.15), 0 1px 1px rgba(0,0,0,.075);
  display: inline-block;
  font-weight: 400;
  border: 1px solid transparent;
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}

.default:disabled {
  background-color: #c9c9c9;
  color: #4f4e4e;
}

.default:hover {
  border: 1px solid transparent;
}

/* red */
.red {
  text-shadow: 0 -1px 0 rgba(0,0,0,.2);
  box-shadow: inset 0 1px 0 rgba(255,255,255,.15), 0 1px 1px rgba(0,0,0,.075);
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  color: #fff;
  display: inline-block;
  font-weight: 400;
  border: 1px solid transparent;
  border-color: #D8D8D8;
  background-color: #E94B35;
}
.red:hover:enabled {
  background-color: #a8231f;
  background-position: 0 -15px;
}

.red:disabled {
  opacity: .65;
}

/* Action */
.action {
  text-shadow: 0 -1px 0 rgba(0,0,0,.2);
  box-shadow: inset 0 1px 0 rgba(255,255,255,.15), 0 1px 1px rgba(0,0,0,.075);
  color: #fff;
  background-color: #1AAF5D;
  display: inline-block;
  font-weight: 400;
  border: 1px solid transparent;
  border-color: #D8D8D8;
  background-color: #1AAF5D;
}

.action:hover:enabled {
  background-color: #419641;
  background-position: 0 -15px;
}

.action:disabled {
  opacity: .65;
  cursor: none;
  background-color: aquamarine;
}

/* blue */
.blue {
  text-shadow: 0 -1px 0 rgba(0,0,0,.2);
  box-shadow: inset 0 1px 0 rgba(255,255,255,.15), 0 1px 1px rgba(0,0,0,.075);
  color: #fff;
  background-color: #254b7c;
  display: inline-block;
  font-weight: 400;
  border: 1px solid transparent;
  background-color: #2C97DE;
  border-color: #D8D8D8;
}

.blue:hover:enabled {
  background-color: #254b7c;
  background-position: 0 -15px;
}

.blue:disabled {
  opacity: .65;
}


/* filter */
.filter {
  padding: 5px 5px 5px 5px;
  background-color: #EFEFEF;
  color: #929292;
  border: 1px solid #ccc;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  border: 1px solid #D4D4D4;
}

.filter:hover {
  background-color: #D8D8D8;
}
