.fieldContainer {
  display: flex;
  padding: 5px 0px;
}

.fieldLabel {
  width: 100%;
  font-weight: bold;
  flex: 1;
}

.fieldValue {
  width: 100%;
  flex: 3;
}