.container {
  flex-direction: row;
}

.link {
  color: #585656;
  font-size: 1em;
  vertical-align: middle;
}

.link:hover {
  font-weight: bold;
  color: #117440;
  text-decoration: none;
}

.active {
  font-weight: bold;
  color: black;
  text-decoration: none;
}


.breadcrumbIcon {
  width: 0.9em;
}
