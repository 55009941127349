.icon {
  width: 15px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 3px;
}

.action {
  cursor: pointer;
  color: #535353;
  font-weight: 400;
  font-size: 0.8em;
}

.action:hover {
  color: #0097EC;
  text-decoration: underline;
}
