.button {
  background-color: #C57878;
  padding: 0.1em;
  box-shadow: none;
  border: none;
}

.button:hover {
  background-color: #A96969;
  box-shadow: none;
  border: none;
}

.image {
  font-family: 'Roboto', sans-serif;
}
