.default
{
  margin: 1em 0 0 0;
  text-align: center;
}

.pagination
{
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 1.8em;
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;
}

.listItem
{
    float: left;
    margin-right: 0px;
    overflow: hidden;
    display: inline;
    list-style-type: none;
    margin: 0 3px;
    cursor: pointer;
    color:inherit;
}

.listItem.selected
{
  border-bottom: 1px solid #777;
}

.listItem:hover,
.listItem:focus
{
    text-decoration: underline;
    background-color: #eee;
    color:inherit;
}

.selected
{
  font-weight: 900;
  pointer-events: none;
  cursor: none;
}

.link
{
    position: relative;
    float: left;
    padding: 5px 10px;
    border: none;
    border-bottom: 1px solid lightgray;
    border-radius: 0px;
    color: inherit;
    margin-left: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    background-color: inherit;
}