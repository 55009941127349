.container {
  width: 100%;
  display: flex;
}

.infoContainer {
  min-width: 40%;
  margin-right: 2em;
}

.inputContainer {
  display: inline-block;
  width: 60%;
  max-width: 60%;
}

.flag {
  margin-right: 1em;
}
