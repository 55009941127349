.container {
  width: 100%;
}

.row {
  margin: 20px;
  height: 3em;
  padding-right: 4em;
}

.flexRow {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.flex {
  flex: 1;
}

.column {
  margin: auto;;
}

.center {
  margin: auto;
}

.text {
  margin: auto;
  vertical-align: middle;
}
