.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 800px;
  padding: 40px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(81, 81, 81, 0.6);
}

.form {
  width: 80%;
  margin: 0 auto;
}

.inputContainer {
  display: flex;
  padding: 10px 0px;
}

.label {
  width: 100%;
  flex: 1;
}

.input {
  width: 100%;
  flex: 2;
}

.buttonContainer {
  margin-top: 20px;
  text-align: right;
}

.errorMessage {
  color: red;
  font-weight: bold;
  text-align: center;
  margin-left: 33.3%;
}