.userInfoWrapper {
  width: 200px;
  float: right;
  display: flex;
}

.profilePicture {
  max-width: 40px;
  max-height: 40px;
  margin-right: 15px;
  float: left;
}

.userInfo {
  margin: auto 0;
}

.name {
  color: black;
  font-weight: bold;
  font-size: 11px;
}

.accountNumber {
  color: black;
  font-size: 11px;
}

.versionNumber {
  color: black;
  font-size: 9px;
  display: inline-block;
}

@media (max-width: 500px) {
  .userInfoWrapper {
    width: 150px;
    float: right;
  }

  .profilePicture {
    max-width: 25px;
    max-height: 25px;
    margin-right: 5px;
  }

  .name {
    font-size: 9px;
  }

  .accountNumber {
    font-size: 8px;
  }
}