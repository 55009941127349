.header {
  background-color: #fff;
  border-bottom: 1px solid #DFDFDF;
  height: var(--header-height);
  order: 1;
  padding: 10px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
}

.rightWrapper {
  float: right;
  width: 100%;
}

.logo {
  max-width: 120px;
  max-height: 240px;
  float: left;
  margin-left: 20px;
}

.versionNumber {
  color: #737373;
  font-size: 9px;
  bottom: 0;
  right: 0;
  margin: 5px 10px;
  position: absolute;
}

@media screen and (max-width: 768px) {
  .header {
    min-height: var(--header-height-mobile);
    top: 0;
    left: 0;
    position: fixed;
    z-index: 999;
  }

  .versionNumber {
    font-size: xx-small;
  }

  .logo {
    max-width: 90px;
    max-height: 180px;
  }
}