.table {
  font-size: 12px;
}

.link {
  font-size: 12px;
  color: #333333;
  font-weight: normal;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
}

.deleteButton {
  background-color: #C4C4C4;
  color: #8B8B8B;
  box-shadow: 0px 2px rgba(0, 0, 0, 0.25);
  font-size: 14px;
  font-weight: bold;
  padding: 5px 7px;
  line-height: 12px;
}

.deleteButton:hover {
  background-color: #B7B7B7;
}

@media only screen and (max-width: 768px) {
}
