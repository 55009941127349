.table {
  font-size: 12px;
}

.link {
  font-size: 12px;
  color: #333333;
  font-weight: normal;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
}

@media only screen and (max-width: 768px) {
}
