.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 40em;
  padding: 5em;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(81, 81, 81, 0.6);
}

.inputContainer {
  display: flex;
  padding-top: 1em;
  padding-bottom: 1em;
}

.label {
  width: 5em;
}

.inputLabel {
  width: 10em;
}

.input {
  width: 100%;
}

.errorMessage {
  color: red;
  font-weight: bold;
  text-align: center;
  margin-left: 33.3%;
}
