.input {
  padding: 6px 12px;
  font-size: 12px;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  font-weight: bold;
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
}

.input[disabled], .input[readonly], input[disabled] .input {
  background-color: #eee;
  opacity: 1;
}

.input[disabled], input[disabled] .input {
  cursor: not-allowed;
}

.normal {

}

.error {
  border: 1px solid #A93D32;
  background-color: #F3DEDE;
}

.error:focus {
  outline-color: #A93D32;
}

