@import url(//netdna.bootstrapcdn.com/bootstrap/3.0.0/css/bootstrap-glyphicons.css);
@import url(//netdna.bootstrapcdn.com/bootstrap/3.0.0/css/bootstrap-glyphicons.css);
@import url(https://fonts.googleapis.com/css?family=Lato:400,700);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap);
.styles_container__TAH67 {
  margin-left: 5px;
  cursor: pointer;
  padding: 4px;
  display: table-cell;
  vertical-align: middle;
  display: inline-block;
  vertical-align: middle;
}

.styles_bar1__etNri, .styles_bar2__3igOf, .styles_bar3__1WQV1 {
  width: 35px;
  height: 5px;
  background-color: #333;
  margin: 6px 0;
  transition: 0.4s;
}

.styles_change__3TSkB .styles_bar1__etNri {
  -webkit-transform: rotate(-45deg) translate(-9px, 6px);
  transform: rotate(-45deg) translate(-9px, 6px);
}

.styles_change__3TSkB .styles_bar2__3igOf {opacity: 0;}

.styles_change__3TSkB .styles_bar3__1WQV1 {
  -webkit-transform: rotate(45deg) translate(-8px, -8px);
  transform: rotate(45deg) translate(-8px, -8px);
}
.styles_userInfoWrapper__1FQAn {
  width: 200px;
  float: right;
  display: -webkit-flex;
  display: flex;
}

.styles_profilePicture__3ezwA {
  max-width: 40px;
  max-height: 40px;
  margin-right: 15px;
  float: left;
}

.styles_userInfo__3uEcN {
  margin: auto 0;
}

.styles_name__1FbQW {
  color: black;
  font-weight: bold;
  font-size: 11px;
}

.styles_accountNumber__3PF_x {
  color: black;
  font-size: 11px;
}

.styles_versionNumber__3Mqn6 {
  color: black;
  font-size: 9px;
  display: inline-block;
}

@media (max-width: 500px) {
  .styles_userInfoWrapper__1FQAn {
    width: 150px;
    float: right;
  }

  .styles_profilePicture__3ezwA {
    max-width: 25px;
    max-height: 25px;
    margin-right: 5px;
  }

  .styles_name__1FbQW {
    font-size: 9px;
  }

  .styles_accountNumber__3PF_x {
    font-size: 8px;
  }
}
.styles_header__2b9cJ {
  background-color: #fff;
  border-bottom: 1px solid #DFDFDF;
  height: var(--header-height);
  -webkit-order: 1;
          order: 1;
  padding: 10px;
  width: 100%;
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.styles_rightWrapper__2QYKx {
  float: right;
  width: 100%;
}

.styles_logo__U6ZsX {
  max-width: 120px;
  max-height: 240px;
  float: left;
  margin-left: 20px;
}

.styles_versionNumber__1VGpi {
  color: #737373;
  font-size: 9px;
  bottom: 0;
  right: 0;
  margin: 5px 10px;
  position: absolute;
}

@media screen and (max-width: 768px) {
  .styles_header__2b9cJ {
    min-height: var(--header-height-mobile);
    top: 0;
    left: 0;
    position: fixed;
    z-index: 999;
  }

  .styles_versionNumber__1VGpi {
    font-size: xx-small;
  }

  .styles_logo__U6ZsX {
    max-width: 90px;
    max-height: 180px;
  }
}
.styles_wrapper__bGF_M {
  padding: 25px 30px 20px 30px;
  border-top: 1px solid #DFDFDF;
}

.styles_title__39tJu {
  padding-bottom: 5px;
  font-weight: bold;
  font-size: 12px;
  color: #666666;
}

.styles_list__1jBIW {
  padding-left: 20px;
  list-style-type: none;
  font-size: 12px;
}

.styles_option__3eRPu {
  line-height: 3em;
  text-decoration: none;
  color: #737373;
  font-weight: medium;
}

.styles_active__13I5m {
  font-weight: bold;
  color: var(--vbop-main-colour);
}
.styles_sidebar__1QJfB {
  background-color: #F4F4F4;
  border-right: 2px solid #DFDFDF;
  min-height: calc(100vh - var(--header-height));
  width: var(--sidebar-width);
  -webkit-order: 2;
          order: 2;
  overflow-y: auto;
}

.styles_collapsed__kVNV4 {
  display: none;
}

@media screen and (max-width: 768px) {
  .styles_sidebar__1QJfB {
    width: var(--sidebar-width-mobile);
    height: 0;
    min-height: none;
    position: fixed;
    top: var(--header-height-mobile);
    left: 0;
    bottom: 0;
    z-index: 1;
    -webkit-flex: none;
            flex: none;
    transition: -webkit-transform .3s ease-in-out;
    transition: transform .3s ease-in-out;
    transition: transform .3s ease-in-out, -webkit-transform .3s ease-in-out;
    -webkit-transform: translateX(var(---sidebar-width-mobile));
            transform: translateX(var(---sidebar-width-mobile));
    -webkit-overflow-scrolling: touch;
  }
}

.styles_wrapper__3Mm0- {
  margin-top: 60px;
}

.styles_line__K5SUO {
  width: 100%;
  margin: 0px;
  border: none;
  height: 1px;
  /* Set the hr color */
  color: #D7D7D7; /* old IE */
  background-color: #D7D7D7; /* Modern Browsers */
}

.styles_footer__BrZq6 {
  padding: 20px 30px;
  color: #777777;
}

.styles_link__1NJsy {
  font-size: x-small;
  font-weight: normal;
  color: #777777;
}

.styles_link__1NJsy:hover {
  color: #777777;
}
.styles_svg__1OZ7v {
  width: 150px;
  height: 150px;
}

.styles_circleDiv__2JFgH {
  text-align: center;
}
#styles_progressInput__1wvaz {
  margin: 20px auto;
  width: 30%;
}

.styles_circleBackground__meLfM,
.styles_circleProgress__MMjuU {
  fill: none;
}

.styles_circleBackground__meLfM {
  stroke: #ddd;
}

.styles_circleProgress__MMjuU {
  stroke: #117440;
  stroke-linecap: butt;
  stroke-linejoin: butt;
  stroke-width: 16;
}

.styles_circleText__26_ff {
  font-size: 3em;
  font-weight: bold;
  fill: #5F5F5F;
  color: #5F5F5F;
}

.styles_circleText__26_ff.styles_smallCircleText__3PC4A {
  font-size: 2em;
}

.styles_circleTextSubtitle__3jCTD {
  font-size: 1.5em;
  font-weight: bold;
  color: #5F5F5F;
}

.styles_title__24kCR {
  color:var(--vbop-main-colour);
  font-size: 1em;
}

@media (max-width: 768px) {
  .styles_svg__1OZ7v {
    width: 75px;
    height: 75px;
  }

  .styles_title__24kCR {
    font-size: 1em;
  }
}
.styles_section__2US3A {
  width: 100%;
}

.styles_line__hR0he {
  margin: 0px;
  border: none;
  margin-bottom: 15px;
  margin-top: 5px;
  height: 1px;
  color: #E7E7E7; /* old IE */
  background-color: #E7E7E7; /* Modern Browsers */
}

.styles_title__2MjG7 {
  color: #5E5E5E;
  font-size: 24px;
  margin-bottom: 0px;
  font-weight: normal;
}

.styles_icon__29jQ7 {
  float: left;
  clear: left;
  margin-right: 8px;
  font-size: 19px;
  margin-top: -4px;
  color: #585858;
}
.styles_wrapper__1S2z9 {
  width: 100%;
}
.styles_wrapper__1g0sf {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  padding: 10px 0px;
}

.styles_buttetPoint__3ED5C {
  max-width: 13px;
  max-height: 13px;
  margin-top: 3px;
  margin-right: 10px;
}

.styles_description__1rrOy {
  font-size: small;
}

.styles_unavilable__2ev8Y {
  font-weight: light;
  color: #ADADAD;
}

.styles_title__2c6yT {
  font-size: small;
  font-weight: bold;
}

.styles_infoWrapper__10e8x {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.styles_panel__1tRkx {
  width: 100%;
}

.styles_scrollable__3YK8i {
  overflow-y: scroll;
}

/* Themes */
.styles_default__3QAXC {
  background-color: white;
  border: 1px solid #EEEEEE;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}

.styles_grey__2qhdD {
  background-color: #F8F8F8;
  border: 1px solid #D2D2D2;
}

.styles_h1__3GXKC {
  font-weight: bold;
  color: #117440;
  margin: 0px;
  padding: 0px;
  margin-bottom: 0.3em;
}

.styles_default__UhmKO {
  margin-bottom: 10px;
  padding: 0px;
  font-size: 12px;
}

.styles_default__EE-M0 {
  width: 100%;
  box-shadow: 2px 2px 4px rgba(0,0,0,.25);
  background-color: #F1F1F1;
  border: 1px solid #D7D7D7;
}

.styles_button__O9wwB {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  text-transform: none;
  text-rendering: auto;
  cursor: pointer;
  user-select: none;
}

.styles_button__O9wwB:disabled {
  pointer-events: none;
  -webkit-filter: grayscale(1);
          filter: grayscale(1)
}

.styles_small__2Mhmf {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
  background: purple;
}

.styles_medium__i_Xcz {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

/* default */
.themes_default__1nrUD {
  text-shadow: 0 1px 0 #fff;
  box-shadow: inset 0 1px 0 rgba(255,255,255,.15), 0 1px 1px rgba(0,0,0,.075);
  display: inline-block;
  font-weight: 400;
  border: 1px solid transparent;
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}

.themes_default__1nrUD:disabled {
  background-color: #c9c9c9;
  color: #4f4e4e;
}

.themes_default__1nrUD:hover {
  border: 1px solid transparent;
}

/* red */
.themes_red__3a63h {
  text-shadow: 0 -1px 0 rgba(0,0,0,.2);
  box-shadow: inset 0 1px 0 rgba(255,255,255,.15), 0 1px 1px rgba(0,0,0,.075);
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  color: #fff;
  display: inline-block;
  font-weight: 400;
  border: 1px solid transparent;
  border-color: #D8D8D8;
  background-color: #E94B35;
}
.themes_red__3a63h:hover:enabled {
  background-color: #a8231f;
  background-position: 0 -15px;
}

.themes_red__3a63h:disabled {
  opacity: .65;
}

/* Action */
.themes_action__1YMY8 {
  text-shadow: 0 -1px 0 rgba(0,0,0,.2);
  box-shadow: inset 0 1px 0 rgba(255,255,255,.15), 0 1px 1px rgba(0,0,0,.075);
  color: #fff;
  background-color: #1AAF5D;
  display: inline-block;
  font-weight: 400;
  border: 1px solid transparent;
  border-color: #D8D8D8;
  background-color: #1AAF5D;
}

.themes_action__1YMY8:hover:enabled {
  background-color: #419641;
  background-position: 0 -15px;
}

.themes_action__1YMY8:disabled {
  opacity: .65;
  cursor: none;
  background-color: aquamarine;
}

/* blue */
.themes_blue__3hs0D {
  text-shadow: 0 -1px 0 rgba(0,0,0,.2);
  box-shadow: inset 0 1px 0 rgba(255,255,255,.15), 0 1px 1px rgba(0,0,0,.075);
  color: #fff;
  background-color: #254b7c;
  display: inline-block;
  font-weight: 400;
  border: 1px solid transparent;
  background-color: #2C97DE;
  border-color: #D8D8D8;
}

.themes_blue__3hs0D:hover:enabled {
  background-color: #254b7c;
  background-position: 0 -15px;
}

.themes_blue__3hs0D:disabled {
  opacity: .65;
}


/* filter */
.themes_filter__XeVxh {
  padding: 5px 5px 5px 5px;
  background-color: #EFEFEF;
  color: #929292;
  border: 1px solid #ccc;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  border: 1px solid #D4D4D4;
}

.themes_filter__XeVxh:hover {
  background-color: #D8D8D8;
}

.styles_default__3Rmw1
{
  -webkit-border-radius: 2px;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  border: 1px solid #AAA;
  color: #555;
  border: 1px solid #ccc;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 30px;
  font-size: 12px;
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  font-weight: bold;
  padding: 5px 30px 5px 10px;
}

.styles_input__39ten {
  padding: 6px 12px;
  font-size: 12px;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  font-weight: bold;
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
}

.styles_input__39ten[disabled], .styles_input__39ten[readonly], input[disabled] .styles_input__39ten {
  background-color: #eee;
  opacity: 1;
}

.styles_input__39ten[disabled], input[disabled] .styles_input__39ten {
  cursor: not-allowed;
}

.styles_normal__N2Vgv {

}

.styles_error__dn-59 {
  border: 1px solid #A93D32;
  background-color: #F3DEDE;
}

.styles_error__dn-59:focus {
  outline-color: #A93D32;
}


.styles_th__1WMdo {
  padding: 6px 8px;
  text-align: left;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
}

.styles_sortable__1ZWgR {
  cursor: pointer;
}

.styles_table__2SoOV {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}

th {
  padding: 10px 12px;
  text-align: left;
  font-weight: bold;
  border-bottom: 1px solid #656565;
}

td {
  border-bottom: 1px solid#ddd;
  text-align: left;
  padding: 5px 12px;
}

.styles_light__19pPq th {
  border-bottom: 1px solid #E3E3E3;
  padding: 10px 12px 4px 12px;
}

.styles_light__19pPq td {
  border-bottom: none;
}

.styles_disabledRow__3P1OI {
  background-color: rgba(0, 0, 0, 0.1);
  color: grey;
}

.styles_errorRow__Z6IVc {
  background-color: rgba(248, 36, 36, 0.381);
}


.styles_flag__1S2h-
{
  float: left;
  background-color: inherit;
  width: 25px;
}


@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .styles_flag__1S2h- {
    width: 40px;
  }
}
.styles_flag__mG-FD
{
  width: 20px;
}

.styles_greyBg__2-sd1
{
  background: #F5F5F5;
}

.styles_leftMargin__1pdJl
{
  margin-left:2px;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .styles_mobileHidden__mmzSa {
    display: none;
  }
}

.styles_option__1Yeac {
  display: inline;
  margin-right: 2px;
  border: none;
  padding: 1px;
  opacity: 0.4;
}

.styles_option__1Yeac:hover {
  opacity: 1;
}

.styles_container__37-yI {
  color: #929292;
  border: 1px solid #ccc;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  background-color: #FAFAFA;
}

.styles_default__2k6da
{
  margin: 1em 0 0 0;
  text-align: center;
}

.styles_pagination__1QLn7
{
  margin: 0;
  padding: 0;
  list-style: none;
  line-height: 1.8em;
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;
}

.styles_listItem__2O5WP
{
    float: left;
    margin-right: 0px;
    overflow: hidden;
    display: inline;
    list-style-type: none;
    margin: 0 3px;
    cursor: pointer;
    color:inherit;
}

.styles_listItem__2O5WP.styles_selected__2wpi0
{
  border-bottom: 1px solid #777;
}

.styles_listItem__2O5WP:hover,
.styles_listItem__2O5WP:focus
{
    text-decoration: underline;
    background-color: #eee;
    color:inherit;
}

.styles_selected__2wpi0
{
  font-weight: 900;
  pointer-events: none;
  cursor: none;
}

.styles_link__3Y3Cb
{
    position: relative;
    float: left;
    padding: 5px 10px;
    border: none;
    border-bottom: 1px solid lightgray;
    border-radius: 0px;
    color: inherit;
    margin-left: 0;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    background-color: inherit;
}

.styles_option__2pnco {
  background: none;
  border: 0px;
  color: #37AAEE;
  padding: 5px;
  font-size: 14px;
}


.styles_tableWrapper__3efbe
{
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  overflow-x: auto;
}

.styles_table__ZgTM-
{
  width: 100%;
  padding: 5px;
  font-size: small;
}

  .styles_table__ZgTM- tbody tr
  {
    line-height: 1.5em;
    min-height: 30px;
  }

td
{
  border-bottom: 1px solid #ddd;
  text-align: left;
  padding: 5px 12px;
}

.styles_flag__3qApq
{
  width: 20px;
}

.styles_greyBg__3yvgz
{
  background: #F5F5F5;
}

.styles_leftMargin__11v5-
{
  margin-left:2px;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .styles_mobileHidden__2D-YT {
    display: none;
  }
}


.styles_orderStatus__1GqCG {
  border-radius: 1px;
  background: #E9E9E9;
  padding: 2px;
  width: 90px;
  height: 20px;
  color: white;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  font-size: x-small;
}

.styles_received__oX2r0 {
  background-color: #68C7EC;
}

.styles_awaitingApproval__2F_5W {
  background-color: #F4D161;
}

.styles_awaitingApproval2__3n0YR {
  background-color: #F4D161;
}

.styles_processing__uZtpd {
  background-color: #F3B86D;
}

.styles_partiallyDespatched__wfJPz {
  background-color: #B9A7D4;
}

.styles_despatched__3qXc7 {
  background-color: #AB8DDD;
}

.styles_cancelled__11tll {
  background-color: #A8A8A8;
}

.styles_returned__3Cgtg {
  background-color: #F36D6D;
}

.styles_default__1BF7f {
  padding: 6px 12px;
  font-size: 12px;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  font-weight: bold;
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
}

.styles_wrapper__2Rh6f {
  width: 100%;
  min-height: 100px;
  padding: 10px;
  display: -webkit-flex;
  display: flex;
  margin: 5px;
}

.styles_buttonWrapper__3cmOF {
  display: -webkit-flex;
  display: flex;
  margin-right: 15px;
  -webkit-flex: 1 1;
          flex: 1 1;
  padding-top: 10px;
}

.styles_button__1PEK9 {
  background: #DD5C5C;
  color: #fff;
  border: none;

  width: 100px;
  height: 40px;
}

.styles_infoWrapper__lT2Ma {
  width: 100%;
  -webkit-flex: 4 1;
          flex: 4 1;
}

.styles_name__33xY6 {
  font-size: 12px;
  font-weight: bold;
}

.styles_description__2Bude {
  color: #999999;
}

.styles_active__aMqho {
  background: #66BD6E;
}
/* Themes */
.styles_default__ESu1x {
  color: #545454;
}

.styles_alternative__1lhr7 {
  color: #FF9900;
}

.styles_light__1FLPt {
  color: #B4B4B4;
}

.styles_success__1wpOa {
  color: #7CB96D;
}

.styles_error__1QzYs {
  color: red;
}

.styles_black__3E-88 {
  color: black;
}

.styles_white__3CHGW {
  color: white;
}

.styles_faded__2vBaj {
  color: #C8C7C7;
}

/* Sizes */
.styles_xxsmall__1D9wT {
  font-size: 8px;
}

.styles_xsmall__6_YBo {
  font-size: 10px;
}

.styles_small__1M7sV {
  font-size: 12px;
}

.styles_medium__QNEG6 {
  font-size: 14px;
}

.styles_large__2M_1d {
  font-size: 16px;
}

.styles_xlarge__2OK3n {
  font-size: 20px;
}

.styles_xxlarge__3lxvp {
  font-size: 24px;
}

.styles_bold__34xRX {
  font-weight: bold;
}

.styles_italic__2Qq_J {
  font-style: italic;
}

.styles_horizontalRule__uKWwG {

}

.styles_wrapper__y9eug {
  width: 100%;
}

.styles_title__It7o2 {
  margin-left: 1em;
}

.styles_horizontalRule__3FFt_ {
  margin-top: 0;
}

.styles_container__3ibcD {
  width: 100%;
}

.styles_row__2Yz78 {
  margin: 20px;
  height: 3em;
  padding-right: 4em;
}

.styles_flexRow__3R2XM {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 100%;
}

.styles_flex__3vQhG {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.styles_column__6ZATT {
  margin: auto;;
}

.styles_center__fZkAO {
  margin: auto;
}

.styles_text__3Dhni {
  margin: auto;
  vertical-align: middle;
}

.styles_container__eyd1- {
  padding: 4em 3em;
  width: 25em;
  margin-right: 2em;
}

.styles_dataList__3FpLF {
  width: 100%;
  overflow: hidden;
}

.styles_dataList__3FpLF dt {
	clear: left;
  float: left;
  width: 40%;
  font-weight: bold;
  padding: 0.2em 0em;
}

.styles_dataList__3FpLF dd {
	float: left;
  padding: 0.2em 0em;
}

.styles_tabs__3_-Py {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  margin-top: 45px;
}

.styles_tab__3miz4 {
  font-weight: bold;
  color:#737373;
  background-color: #ECECEC;
  border:none;
}

.styles_tab__3miz4:hover {
  cursor: pointer;
}

.styles_active__jCKpI {
  background-color:#FAFAFA;
  cursor: default;
}

.styles_small__1eqy- {
  padding: 0.3em 2.5em;
}

.styles_medium__1VZwO {
  padding: 0.7em 5em;
}

.styles_large__3Z3Rb {
  padding: 1em 7em;
}

.styles_container__2qmlz {
  width: 100%;
}

.styles_node__37kmN {
  list-style-type: none;
  padding: 0.2em;
  padding-right: 0.3em;
  padding-left: 2.0em;
}

.styles_node__37kmN:hover {
  border-radius: 0.2em;
  background-color: #E6E6E6;
  cursor: pointer;
}

.styles_addNode__1dXVe {
  list-style-type: none;
  padding-right: 0.3em;
  padding-left: 3.3em;
}

.styles_addNodeTextContainer__3T8Jt {
  font-weight: bold;
  color: #B4B4B4;
  font-size: 1.2em;
  width: auto;
  display: inline-block;
  padding: 0.1em;
}

.styles_addNodeTextContainer__3T8Jt:hover {
  color: #0097EC;
  cursor: pointer;
}

.styles_nodeSelected__4bxit {
  border-radius: 0.2em;
  background-color: rgba(0, 151, 236, 0.37);
}

.styles_caretContainer__1UipS {
  width: 1.4em;
  display: inline-block;
}

.styles_caret__21gnl {
  font-size: 0.8em;
  color: #474747;
}

.styles_title__2ybuu {

}

.styles_removeIconContainer__2EpFL {
  float: right;
  width: 1.4em;
  height: 100%;
  text-align: center;
}

.styles_removeIcon__2WmVD {
  color: white;
  margin: auto;
}

.styles_nestedList__1t3Hp {
  margin-left: 30px;
}

.styles_addContainer__37R0b {
  padding-left: 2.0em;
}

.styles_addText__2fPu3 {
  font-weight: bold;
  color: #B4B4B4;
  font-size: 1.2em;
  width: auto;
  display: inline-block;
  padding: 0.1em;
}

.styles_addText__2fPu3:hover {
  color: #0097EC;
  cursor: pointer;
}

.styles_container__a8an2 {
  background-color: #F8F8F8;
  border: 1px solid #D8D8D8;
  border-radius: 0.3em;
  margin-right: 1em;
  padding: 0.2em;
  padding-left: 0.8em;
  padding-right: 0.8em;
  box-shadow: 0px 4px 4px rgba(211, 211, 211, 0.25);
  cursor: default;
}

.styles_container__a8an2:hover {
}

.styles_text__1jsQW {
  margin-right: 1em;
  color: #898989
}

.styles_dismissIcon__PDNS4 {
  color: #E3E3E3;
  cursor: pointer;
}

.styles_dismissIcon__PDNS4:hover {
  color: #B7B7B7;
}

.styles_container__rHZ8c {
  width: 100%;
  display: -webkit-flex;
  display: flex;
}

.styles_infoContainer__2-guA {
  min-width: 40%;
  margin-right: 2em;
}

.styles_inputContainer__1WMe0 {
  display: inline-block;
  width: 60%;
  max-width: 60%;
}

.styles_flag__18XM6 {
  margin-right: 1em;
}

.styles_modal__Xuhrt {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: white;
  width: 40em;
  padding: 5em;
}

.styles_overlay__2waU1 {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(81, 81, 81, 0.6);
}

.styles_inputContainer__2FRhZ {
  display: -webkit-flex;
  display: flex;
  padding-top: 1em;
  padding-bottom: 1em;
}

.styles_label__1ZCHf {
  width: 5em;
}

.styles_inputLabel__7dW1F {
  width: 10em;
}

.styles_input__1vnqY {
  width: 100%;
}

.styles_errorMessage__3LKLv {
  color: red;
  font-weight: bold;
  text-align: center;
  margin-left: 33.3%;
}

.styles_icon__1TZXN {
  width: 15px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 3px;
}

.styles_action__2cjkh {
  cursor: pointer;
  color: #535353;
  font-weight: 400;
  font-size: 0.8em;
}

.styles_action__2cjkh:hover {
  color: #0097EC;
  text-decoration: underline;
}

.styles_modal__3bFYm {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: white;
  width: 800px;
  padding: 40px;
}

.styles_overlay__1dTrK {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(81, 81, 81, 0.6);
}

.styles_form__2z7-C {
  width: 80%;
  margin: 0 auto;
}

.styles_inputContainer__34-5W {
  display: -webkit-flex;
  display: flex;
  padding: 10px 0px;
}

.styles_label__1P7W6 {
  width: 100%;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.styles_input__31idv {
  width: 100%;
  -webkit-flex: 2 1;
          flex: 2 1;
}

.styles_buttonContainer__32eKy {
  margin-top: 20px;
  text-align: right;
}

.styles_errorMessage__2ytuY {
  color: red;
  font-weight: bold;
  text-align: center;
  margin-left: 33.3%;
}
.styles_modal__3q65o {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: white;
  width: 800px;
  padding: 40px;
}

.styles_overlay__1hEiy {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(81, 81, 81, 0.6);
}

.styles_form__yfIl5 {
  width: 80%;
  margin: 0 auto;
}

.styles_inputContainer__ccMTc {
  display: -webkit-flex;
  display: flex;
  padding: 10px 0px;
}

.styles_label__1X5mC {
  width: 100%;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.styles_input__1j3PF {
  width: 100%;
  -webkit-flex: 2 1;
          flex: 2 1;
}

.styles_buttonContainer__hv14h {
  margin-top: 20px;
  text-align: right;
}

.styles_errorMessage__s1eEG {
  color: red;
  font-weight: bold;
  text-align: center;
  margin-left: 33.3%;
}
.styles_fieldContainer__3QqVZ {
  display: -webkit-flex;
  display: flex;
  padding: 5px 0px;
}

.styles_fieldLabel__2eRLn {
  width: 100%;
  font-weight: bold;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.styles_fieldValue__3LWmH {
  width: 100%;
  -webkit-flex: 3 1;
          flex: 3 1;
}
.styles_modal__2YeWM {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background-color: white;
  width: 1000px;
  padding: 40px;
}

.styles_overlay__1ZNpx {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(81, 81, 81, 0.6);
}

.styles_columnWrapper__8111T {
  display: -webkit-flex;
  display: flex;
  padding: 20px;
}

.styles_column__lzQK8 {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.styles_input__2SLHz {
  width: 100%;
}

.styles_verticalLine__1ob0H {
  width: 0px;
	border-left: 1px solid #C8C8C8;
  height: auto;
  margin: 10px 60px;
}

.styles_replaceButtonContainer__2yUMf {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.styles_containerBar__ZSWsq {
  height: 10px;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.styles_innerBar__1S_nr {
  float: left;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  text-align: center;
  background-color: #337ab7;
  box-shadow: inset 0 -1px 0 rgba(0,0,0,.15);
}

.styles_blue__3MFJH {
  background-color: #337ab7;
}

.styles_green__3SpwC {
  background-color: #55C360;
}

.styles_table__2WdpO {
  font-size: 12px;
}

.styles_link__1BC44 {
  font-size: 12px;
  color: #333333;
  font-weight: normal;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
}

@media only screen and (max-width: 768px) {
}

.styles_table__9qZqI {
  font-size: 12px;
}

.styles_link__1Fxx_ {
  font-size: 12px;
  color: #333333;
  font-weight: normal;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
}

@media only screen and (max-width: 768px) {
}

.styles_table__2U_Ia {
  font-size: 12px;
}

.styles_link__1BGZV {
  font-size: 12px;
  color: #333333;
  font-weight: normal;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
}

@media only screen and (max-width: 768px) {
}

.styles_default__1ajSA {
  display: inline-block;
  background-color: grey;
  color: white;
  width: 110px;
  font-size: 12px;
  padding: 3px 10px;
  font-family: 'Lato', sans-serif;
  text-align: left;
}

.styles_draft__19ldJ {
  background-color: #559BC3;
}

.styles_confirmed__1ci-- {
  background-color: #D4BC3E;
}

.styles_processing__3U8jN {
  background-color: #B64BC8;
}

.styles_completed__1VQD_ {
  background-color: #55C360;
}

.styles_table__3K1r- {
  font-size: 12px;
}

.styles_link__1s_QT {
  font-size: 12px;
  color: #333333;
  font-weight: normal;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
}

@media only screen and (max-width: 768px) {
}

.styles_input__20vqc {
  padding: 6px 12px;
  font-size: 12px;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  font-weight: bold;
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  resize: none;
}

.styles_input__20vqc[disabled], .styles_input__20vqc[readonly], input[disabled] .styles_input__20vqc {
  background-color: #eee;
  opacity: 1;
}

.styles_input__20vqc[disabled], input[disabled] .styles_input__20vqc {
  cursor: not-allowed;
}

.styles_resizable__yOjCW {
  resize: both;
}

.styles_button__1_aGP {
  background-color: #78B2C5;
  padding: 0.1em;
  box-shadow: none;
  border: none;
}

.styles_button__1_aGP:hover {
  background-color: #5D838F;
  box-shadow: none;
  border: none;
}

.styles_image__3jkix {
  font-family: 'Roboto', sans-serif;
}

.styles_button__Pi4nX {
  background-color: #C57878;
  padding: 0.1em;
  box-shadow: none;
  border: none;
}

.styles_button__Pi4nX:hover {
  background-color: #A96969;
  box-shadow: none;
  border: none;
}

.styles_image__30xde {
  font-family: 'Roboto', sans-serif;
}

.styles_table__3f5ys {
  font-size: 12px;
}

.styles_link__17wrH {
  font-size: 12px;
  color: #333333;
  font-weight: normal;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
}

@media only screen and (max-width: 768px) {
}

.styles_label__1GY86 {
  vertical-align: middle;
}

.styles_container__3brxq {
  display: inline-block;
}

.styles_container__JuNKG {
  width: 100%;
  box-shadow: 2px 2px 4px rgba(0,0,0,.25);
  background-color: #F1F1F1;
  border: 1px solid hsl(0, 0%, 84%);
  padding: 1em;
}

.styles_settingsContainer__Z9JSH {
  margin-left: 2em;
  font-size: 0.9em;
}

.styles_toggleContainer__1KjZp {
  display: inline-block;
  padding-right: 3em;
}

.styles_optionsContainer__1gczi {
  display: inline-block;
}

.styles_optionLabel__2-Efi {
  margin-right: 1em;
  margin-left: 2em;
}

.styles_checkbox__AxUiY {
  vertical-align: middle;
}

.styles_input__3ZOqb {
  margin-right: 2em;
  width: 8em;
}

.styles_table__2TQHB {
  font-size: 12px;
}

.styles_link__1fW67 {
  font-size: 12px;
  color: #333333;
  font-weight: normal;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
}

.styles_deleteButton__1gxH5 {
  background-color: #C4C4C4;
  color: #8B8B8B;
  box-shadow: 0px 2px rgba(0, 0, 0, 0.25);
  font-size: 14px;
  font-weight: bold;
  padding: 5px 7px;
  line-height: 12px;
}

.styles_deleteButton__1gxH5:hover {
  background-color: #B7B7B7;
}

@media only screen and (max-width: 768px) {
}

.styles_table__2DAVc {
  font-size: 12px;
}

.styles_link__1u-J_ {
  font-size: 12px;
  color: #333333;
  font-weight: normal;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
}

.styles_deleteButton__3deX8 {
  background-color: #C4C4C4;
  color: #8B8B8B;
  box-shadow: 0px 2px rgba(0, 0, 0, 0.25);
  font-size: 14px;
  font-weight: bold;
  padding: 5px 7px;
  line-height: 12px;
}

.styles_deleteButton__3deX8:hover {
  background-color: #B7B7B7;
}

@media only screen and (max-width: 768px) {
}

.styles_container__Yh58V {
  background-color: #ffcc91;
  padding: 8px 35px 8px 14px;
  margin-bottom: 20px;
  border-radius: 4px;
  background-repeat: repeat-x;
  font-size: small;
  line-height: 1.42857143;
  color: white;
}

.styles_container__IkTGv {
  background-color: #FF9191;
  padding: 8px 35px 8px 14px;
  margin-bottom: 20px;
  border-radius: 4px;
  background-repeat: repeat-x;
  font-size: small;
  line-height: 1.42857143;
  color: white;
}

.styles_table__207iw {
  font-size: 12px;
}

.styles_link__154_t {
  font-size: 12px;
  color: #333333;
  font-weight: normal;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
}

@media only screen and (max-width: 768px) {
}

.styles_container__YUEMB {
  width: 100%;
  box-shadow: 2px 2px 4px rgba(0,0,0,.25);
  background-color: #F1F1F1;
  border: 1px solid #D7D7D7;
  padding: 1em;
}

.styles_settingsContainer__3-nQ_ {
  margin-left: 2em;
  font-size: 0.9em;
}

.styles_toggleContainer__2FSDO {
  display: inline-block;
  padding-right: 3em;
}

.styles_optionsContainer__VbRZn {
  display: inline-block;
}

.styles_optionLabel__SEiO7 {
  margin-right: 1em;
  margin-left: 2em;
}

.styles_checkbox__37-E7 {
  vertical-align: middle;
}

.styles_input__1xsv3 {
  margin-right: 2em;
  width: 8em;
}

.styles_table__3VcZA {
  font-size: 12px;
}

.styles_link__1r8cG {
  font-size: 12px;
  color: #333333;
  font-weight: normal;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
}

.styles_deleteButton__mjD4V {
  background-color: #C4C4C4;
  color: #8B8B8B;
  box-shadow: 0px 2px rgba(0, 0, 0, 0.25);
  font-size: 14px;
  font-weight: bold;
  padding: 5px 7px;
  line-height: 12px;
}

.styles_deleteButton__mjD4V:hover {
  background-color: #B7B7B7;
}

@media only screen and (max-width: 768px) {
}

.styles_table__cM52S {
  font-size: 12px;
}

.styles_link__2r974 {
  font-size: 12px;
  color: #333333;
  font-weight: normal;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
}

.styles_deleteButton__1wkDG {
  background-color: #C4C4C4;
  color: #8B8B8B;
  box-shadow: 0px 2px rgba(0, 0, 0, 0.25);
  font-size: 14px;
  font-weight: bold;
  padding: 5px 7px;
  line-height: 12px;
}

.styles_deleteButton__1wkDG:hover {
  background-color: #B7B7B7;
}

@media only screen and (max-width: 768px) {
}


.styles_container__13NOt {
  background-color: #ffc17a;
  padding: 8px 35px 8px 14px;
  margin-bottom: 20px;
  border-radius: 4px;
  background-repeat: repeat-x;
  font-size: small;
  line-height: 1.42857143;
  color: white
}

.styles_warning__2RFLm {
  background-color: #ffc17a;
}

.styles_error__3GA_N {
  background-color: #FF9191;
}

.styles_table__3SdZE {
  font-size: 12px;
}

.styles_link__2fM49 {
  font-size: 12px;
  color: #333333;
  font-weight: normal;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
}

@media only screen and (max-width: 768px) {
}

.styles_table__2rzDG {
  font-size: 12px;
}

.styles_link__C7qQA {
  font-size: 12px;
  color: #333333;
  font-weight: normal;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
}

@media only screen and (max-width: 768px) {
}

.styles_container__3urA3 {
  -webkit-flex-direction: row;
          flex-direction: row;
}

.styles_link__1S25t {
  color: #585656;
  font-size: 1em;
  vertical-align: middle;
}

.styles_link__1S25t:hover {
  font-weight: bold;
  color: #117440;
  text-decoration: none;
}

.styles_active__TUDxg {
  font-weight: bold;
  color: black;
  text-decoration: none;
}


.styles_breadcrumbIcon__1LUT7 {
  width: 0.9em;
}


:root {
  /* header */
  --header-height: 5em;
  --header-height-mobile: var(--header-height);

  /* Sidebar */
  --sidebar-width: 18em;
  --sidebar-width-mobile: var(--sidebar-width);

  --vbop-main-colour:#117440;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Lato', sans-serif;
  background-color: #FAFAFA;
}

.align-content-right {
  text-align: right;
}

.text-align-center {
  text-align: center;
}

.styles_wrapper__Zx6R1 {
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
}

.styles_content__2ntCJ {
  width:calc(100% - var(--sidebar-width));
  min-height: calc(100vh - var(--header-height));
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-order: 3;
          order: 3;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column wrap;
          flex-flow: column wrap;
  padding: 4em;
  padding-top: 2em;
  padding-bottom: 0em;
}

@media screen and (max-width: 768px) {
  .styles_content__2ntCJ {
    top: var(--header-height-mobile);
    position: relative;
    width: 100%;
    z-index: 1;
  }

  .styles_content__2ntCJ:before {
    width: 300vw;
    height: 100vh;
    display: block;
    right: 100vw;
    z-index: 1;
    box-shadow: 200vw 0 0 rgba(rgba(0,0,0,.5), 0);
    transition: box-shadow .6s linear;
    background:red;
  }

  .styles_content__2ntCJ:after {
    display: none;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
  }

  .styles_scrollLock__23KaB {
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
  }
}

.styles_input__V-uBJ {
  width: 100%;
  height: 40px;
  margin: 0;
  padding: 0 20px;
  background: #f8f8f8;
  border: 2px solid #ddd;
  font-size: 14px;
  font-weight: 300;
  line-height: 40px;
  color: #888;
  border-radius: 0px;
  box-shadow: none;
  transition: all .3s;
  display: block;
}

.styles_button__lQuEp {
  width: 100%;
  border: 0;
  font-size: 16px;
  font-weight: 300;
  line-height: 40px;
  height: 40px;
  margin: 0;
  padding: 0 20px;
  vertical-align: middle;
  background:#4aaf51;
  color: #fff;
  border-radius: 0px;
  text-shadow: none;
  transition: all .3s;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
}

.styles_button__lQuEp:hover {
  border: 0;
  opacity: 0.5;
  color: #fff;
}


.styles_formHeader__8beQj {
  background-color: white;
  padding: 25px 25px 30px 25px;
}

.styles_formBody__3iTUN {
  background-color: #eee;
  padding: 25px 25px 30px 25px;
}

.styles_errorMessage__1NoPA {
  color: red;
  margin: 5px 0px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Lato', sans-serif;
}

.content {
  padding: 30px 30px 50px 30px;
}

/*!
 * Bootstrap Grid v4.2.1 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
html {
  box-sizing: border-box;
  -ms-overflow-style: scrollbar;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col {
  -webkit-flex-basis: 0;
          flex-basis: 0;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  -webkit-flex: 0 0 auto;
          flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  -webkit-flex: 0 0 8.333333%;
          flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  -webkit-flex: 0 0 16.666667%;
          flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  -webkit-flex: 0 0 25%;
          flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -webkit-flex: 0 0 33.333333%;
          flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  -webkit-flex: 0 0 41.666667%;
          flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  -webkit-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -webkit-flex: 0 0 58.333333%;
          flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  -webkit-flex: 0 0 66.666667%;
          flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  -webkit-flex: 0 0 75%;
          flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -webkit-flex: 0 0 83.333333%;
          flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  -webkit-flex: 0 0 91.666667%;
          flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  -webkit-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  -webkit-order: -1;
          order: -1;
}

.order-last {
  -webkit-order: 13;
          order: 13;
}

.order-0 {
  -webkit-order: 0;
          order: 0;
}

.order-1 {
  -webkit-order: 1;
          order: 1;
}

.order-2 {
  -webkit-order: 2;
          order: 2;
}

.order-3 {
  -webkit-order: 3;
          order: 3;
}

.order-4 {
  -webkit-order: 4;
          order: 4;
}

.order-5 {
  -webkit-order: 5;
          order: 5;
}

.order-6 {
  -webkit-order: 6;
          order: 6;
}

.order-7 {
  -webkit-order: 7;
          order: 7;
}

.order-8 {
  -webkit-order: 8;
          order: 8;
}

.order-9 {
  -webkit-order: 9;
          order: 9;
}

.order-10 {
  -webkit-order: 10;
          order: 10;
}

.order-11 {
  -webkit-order: 11;
          order: 11;
}

.order-12 {
  -webkit-order: 12;
          order: 12;
}

.offset-1 {
  margin-left: 8.333333%;
}

.offset-2 {
  margin-left: 16.666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333%;
}

.offset-5 {
  margin-left: 41.666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333%;
}

.offset-8 {
  margin-left: 66.666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333%;
}

.offset-11 {
  margin-left: 91.666667%;
}

@media (min-width: 576px) {
  .col-sm {
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    -webkit-flex: 0 0 8.333333%;
            flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-sm-2 {
    -webkit-flex: 0 0 16.666667%;
            flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-sm-3 {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    -webkit-flex: 0 0 33.333333%;
            flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-sm-5 {
    -webkit-flex: 0 0 41.666667%;
            flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-sm-6 {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    -webkit-flex: 0 0 58.333333%;
            flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-sm-8 {
    -webkit-flex: 0 0 66.666667%;
            flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-sm-9 {
    -webkit-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    -webkit-flex: 0 0 83.333333%;
            flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-sm-11 {
    -webkit-flex: 0 0 91.666667%;
            flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-sm-12 {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    -webkit-order: -1;
            order: -1;
  }
  .order-sm-last {
    -webkit-order: 13;
            order: 13;
  }
  .order-sm-0 {
    -webkit-order: 0;
            order: 0;
  }
  .order-sm-1 {
    -webkit-order: 1;
            order: 1;
  }
  .order-sm-2 {
    -webkit-order: 2;
            order: 2;
  }
  .order-sm-3 {
    -webkit-order: 3;
            order: 3;
  }
  .order-sm-4 {
    -webkit-order: 4;
            order: 4;
  }
  .order-sm-5 {
    -webkit-order: 5;
            order: 5;
  }
  .order-sm-6 {
    -webkit-order: 6;
            order: 6;
  }
  .order-sm-7 {
    -webkit-order: 7;
            order: 7;
  }
  .order-sm-8 {
    -webkit-order: 8;
            order: 8;
  }
  .order-sm-9 {
    -webkit-order: 9;
            order: 9;
  }
  .order-sm-10 {
    -webkit-order: 10;
            order: 10;
  }
  .order-sm-11 {
    -webkit-order: 11;
            order: 11;
  }
  .order-sm-12 {
    -webkit-order: 12;
            order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    -webkit-flex: 0 0 8.333333%;
            flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-md-2 {
    -webkit-flex: 0 0 16.666667%;
            flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-md-3 {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    -webkit-flex: 0 0 33.333333%;
            flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-md-5 {
    -webkit-flex: 0 0 41.666667%;
            flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-md-6 {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    -webkit-flex: 0 0 58.333333%;
            flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-md-8 {
    -webkit-flex: 0 0 66.666667%;
            flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-md-9 {
    -webkit-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    -webkit-flex: 0 0 83.333333%;
            flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-md-11 {
    -webkit-flex: 0 0 91.666667%;
            flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-md-12 {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    -webkit-order: -1;
            order: -1;
  }
  .order-md-last {
    -webkit-order: 13;
            order: 13;
  }
  .order-md-0 {
    -webkit-order: 0;
            order: 0;
  }
  .order-md-1 {
    -webkit-order: 1;
            order: 1;
  }
  .order-md-2 {
    -webkit-order: 2;
            order: 2;
  }
  .order-md-3 {
    -webkit-order: 3;
            order: 3;
  }
  .order-md-4 {
    -webkit-order: 4;
            order: 4;
  }
  .order-md-5 {
    -webkit-order: 5;
            order: 5;
  }
  .order-md-6 {
    -webkit-order: 6;
            order: 6;
  }
  .order-md-7 {
    -webkit-order: 7;
            order: 7;
  }
  .order-md-8 {
    -webkit-order: 8;
            order: 8;
  }
  .order-md-9 {
    -webkit-order: 9;
            order: 9;
  }
  .order-md-10 {
    -webkit-order: 10;
            order: 10;
  }
  .order-md-11 {
    -webkit-order: 11;
            order: 11;
  }
  .order-md-12 {
    -webkit-order: 12;
            order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333%;
  }
  .offset-md-2 {
    margin-left: 16.666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333%;
  }
  .offset-md-5 {
    margin-left: 41.666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333%;
  }
  .offset-md-8 {
    margin-left: 66.666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333%;
  }
  .offset-md-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    -webkit-flex: 0 0 8.333333%;
            flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-lg-2 {
    -webkit-flex: 0 0 16.666667%;
            flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-lg-3 {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    -webkit-flex: 0 0 33.333333%;
            flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-lg-5 {
    -webkit-flex: 0 0 41.666667%;
            flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-lg-6 {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    -webkit-flex: 0 0 58.333333%;
            flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-lg-8 {
    -webkit-flex: 0 0 66.666667%;
            flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-lg-9 {
    -webkit-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    -webkit-flex: 0 0 83.333333%;
            flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-lg-11 {
    -webkit-flex: 0 0 91.666667%;
            flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-lg-12 {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    -webkit-order: -1;
            order: -1;
  }
  .order-lg-last {
    -webkit-order: 13;
            order: 13;
  }
  .order-lg-0 {
    -webkit-order: 0;
            order: 0;
  }
  .order-lg-1 {
    -webkit-order: 1;
            order: 1;
  }
  .order-lg-2 {
    -webkit-order: 2;
            order: 2;
  }
  .order-lg-3 {
    -webkit-order: 3;
            order: 3;
  }
  .order-lg-4 {
    -webkit-order: 4;
            order: 4;
  }
  .order-lg-5 {
    -webkit-order: 5;
            order: 5;
  }
  .order-lg-6 {
    -webkit-order: 6;
            order: 6;
  }
  .order-lg-7 {
    -webkit-order: 7;
            order: 7;
  }
  .order-lg-8 {
    -webkit-order: 8;
            order: 8;
  }
  .order-lg-9 {
    -webkit-order: 9;
            order: 9;
  }
  .order-lg-10 {
    -webkit-order: 10;
            order: 10;
  }
  .order-lg-11 {
    -webkit-order: 11;
            order: 11;
  }
  .order-lg-12 {
    -webkit-order: 12;
            order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    -webkit-flex-basis: 0;
            flex-basis: 0;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    -webkit-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    -webkit-flex: 0 0 8.333333%;
            flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .col-xl-2 {
    -webkit-flex: 0 0 16.666667%;
            flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .col-xl-3 {
    -webkit-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    -webkit-flex: 0 0 33.333333%;
            flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .col-xl-5 {
    -webkit-flex: 0 0 41.666667%;
            flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .col-xl-6 {
    -webkit-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    -webkit-flex: 0 0 58.333333%;
            flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .col-xl-8 {
    -webkit-flex: 0 0 66.666667%;
            flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .col-xl-9 {
    -webkit-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    -webkit-flex: 0 0 83.333333%;
            flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .col-xl-11 {
    -webkit-flex: 0 0 91.666667%;
            flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .col-xl-12 {
    -webkit-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    -webkit-order: -1;
            order: -1;
  }
  .order-xl-last {
    -webkit-order: 13;
            order: 13;
  }
  .order-xl-0 {
    -webkit-order: 0;
            order: 0;
  }
  .order-xl-1 {
    -webkit-order: 1;
            order: 1;
  }
  .order-xl-2 {
    -webkit-order: 2;
            order: 2;
  }
  .order-xl-3 {
    -webkit-order: 3;
            order: 3;
  }
  .order-xl-4 {
    -webkit-order: 4;
            order: 4;
  }
  .order-xl-5 {
    -webkit-order: 5;
            order: 5;
  }
  .order-xl-6 {
    -webkit-order: 6;
            order: 6;
  }
  .order-xl-7 {
    -webkit-order: 7;
            order: 7;
  }
  .order-xl-8 {
    -webkit-order: 8;
            order: 8;
  }
  .order-xl-9 {
    -webkit-order: 9;
            order: 9;
  }
  .order-xl-10 {
    -webkit-order: 10;
            order: 10;
  }
  .order-xl-11 {
    -webkit-order: 11;
            order: 11;
  }
  .order-xl-12 {
    -webkit-order: 12;
            order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666667%;
  }
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -webkit-flex !important;
  display: flex !important;
}

.d-inline-flex {
  display: -webkit-inline-flex !important;
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: -webkit-flex !important;
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: -webkit-flex !important;
    display: flex !important;
  }
  .d-md-inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: -webkit-flex !important;
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: -webkit-flex !important;
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: -webkit-flex !important;
    display: flex !important;
  }
  .d-print-inline-flex {
    display: -webkit-inline-flex !important;
    display: inline-flex !important;
  }
}

.flex-row {
  -webkit-flex-direction: row !important;
          flex-direction: row !important;
}

.flex-column {
  -webkit-flex-direction: column !important;
          flex-direction: column !important;
}

.flex-row-reverse {
  -webkit-flex-direction: row-reverse !important;
          flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -webkit-flex-direction: column-reverse !important;
          flex-direction: column-reverse !important;
}

.flex-wrap {
  -webkit-flex-wrap: wrap !important;
          flex-wrap: wrap !important;
}

.flex-nowrap {
  -webkit-flex-wrap: nowrap !important;
          flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -webkit-flex-wrap: wrap-reverse !important;
          flex-wrap: wrap-reverse !important;
}

.flex-fill {
  -webkit-flex: 1 1 auto !important;
          flex: 1 1 auto !important;
}

.flex-grow-0 {
  -webkit-flex-grow: 0 !important;
          flex-grow: 0 !important;
}

.flex-grow-1 {
  -webkit-flex-grow: 1 !important;
          flex-grow: 1 !important;
}

.flex-shrink-0 {
  -webkit-flex-shrink: 0 !important;
          flex-shrink: 0 !important;
}

.flex-shrink-1 {
  -webkit-flex-shrink: 1 !important;
          flex-shrink: 1 !important;
}

.justify-content-start {
  -webkit-justify-content: flex-start !important;
          justify-content: flex-start !important;
}

.justify-content-end {
  -webkit-justify-content: flex-end !important;
          justify-content: flex-end !important;
}

.justify-content-center {
  -webkit-justify-content: center !important;
          justify-content: center !important;
}

.justify-content-between {
  -webkit-justify-content: space-between !important;
          justify-content: space-between !important;
}

.justify-content-around {
  -webkit-justify-content: space-around !important;
          justify-content: space-around !important;
}

.align-items-start {
  -webkit-align-items: flex-start !important;
          align-items: flex-start !important;
}

.align-items-end {
  -webkit-align-items: flex-end !important;
          align-items: flex-end !important;
}

.align-items-center {
  -webkit-align-items: center !important;
          align-items: center !important;
}

.align-items-baseline {
  -webkit-align-items: baseline !important;
          align-items: baseline !important;
}

.align-items-stretch {
  -webkit-align-items: stretch !important;
          align-items: stretch !important;
}

.align-content-start {
  -webkit-align-content: flex-start !important;
          align-content: flex-start !important;
}

.align-content-end {
  -webkit-align-content: flex-end !important;
          align-content: flex-end !important;
}

.align-content-center {
  -webkit-align-content: center !important;
          align-content: center !important;
}

.align-content-between {
  -webkit-align-content: space-between !important;
          align-content: space-between !important;
}

.align-content-around {
  -webkit-align-content: space-around !important;
          align-content: space-around !important;
}

.align-content-stretch {
  -webkit-align-content: stretch !important;
          align-content: stretch !important;
}

.align-self-auto {
  -webkit-align-self: auto !important;
          align-self: auto !important;
}

.align-self-start {
  -webkit-align-self: flex-start !important;
          align-self: flex-start !important;
}

.align-self-end {
  -webkit-align-self: flex-end !important;
          align-self: flex-end !important;
}

.align-self-center {
  -webkit-align-self: center !important;
          align-self: center !important;
}

.align-self-baseline {
  -webkit-align-self: baseline !important;
          align-self: baseline !important;
}

.align-self-stretch {
  -webkit-align-self: stretch !important;
          align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    -webkit-flex-direction: row !important;
            flex-direction: row !important;
  }
  .flex-sm-column {
    -webkit-flex-direction: column !important;
            flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    -webkit-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    -webkit-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    -webkit-flex-wrap: wrap !important;
            flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    -webkit-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    -webkit-flex: 1 1 auto !important;
            flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    -webkit-flex-grow: 0 !important;
            flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    -webkit-flex-grow: 1 !important;
            flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    -webkit-flex-shrink: 0 !important;
            flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    -webkit-flex-shrink: 1 !important;
            flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    -webkit-justify-content: flex-end !important;
            justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    -webkit-justify-content: center !important;
            justify-content: center !important;
  }
  .justify-content-sm-between {
    -webkit-justify-content: space-between !important;
            justify-content: space-between !important;
  }
  .justify-content-sm-around {
    -webkit-justify-content: space-around !important;
            justify-content: space-around !important;
  }
  .align-items-sm-start {
    -webkit-align-items: flex-start !important;
            align-items: flex-start !important;
  }
  .align-items-sm-end {
    -webkit-align-items: flex-end !important;
            align-items: flex-end !important;
  }
  .align-items-sm-center {
    -webkit-align-items: center !important;
            align-items: center !important;
  }
  .align-items-sm-baseline {
    -webkit-align-items: baseline !important;
            align-items: baseline !important;
  }
  .align-items-sm-stretch {
    -webkit-align-items: stretch !important;
            align-items: stretch !important;
  }
  .align-content-sm-start {
    -webkit-align-content: flex-start !important;
            align-content: flex-start !important;
  }
  .align-content-sm-end {
    -webkit-align-content: flex-end !important;
            align-content: flex-end !important;
  }
  .align-content-sm-center {
    -webkit-align-content: center !important;
            align-content: center !important;
  }
  .align-content-sm-between {
    -webkit-align-content: space-between !important;
            align-content: space-between !important;
  }
  .align-content-sm-around {
    -webkit-align-content: space-around !important;
            align-content: space-around !important;
  }
  .align-content-sm-stretch {
    -webkit-align-content: stretch !important;
            align-content: stretch !important;
  }
  .align-self-sm-auto {
    -webkit-align-self: auto !important;
            align-self: auto !important;
  }
  .align-self-sm-start {
    -webkit-align-self: flex-start !important;
            align-self: flex-start !important;
  }
  .align-self-sm-end {
    -webkit-align-self: flex-end !important;
            align-self: flex-end !important;
  }
  .align-self-sm-center {
    -webkit-align-self: center !important;
            align-self: center !important;
  }
  .align-self-sm-baseline {
    -webkit-align-self: baseline !important;
            align-self: baseline !important;
  }
  .align-self-sm-stretch {
    -webkit-align-self: stretch !important;
            align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .flex-md-row {
    -webkit-flex-direction: row !important;
            flex-direction: row !important;
  }
  .flex-md-column {
    -webkit-flex-direction: column !important;
            flex-direction: column !important;
  }
  .flex-md-row-reverse {
    -webkit-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    -webkit-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    -webkit-flex-wrap: wrap !important;
            flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    -webkit-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    -webkit-flex: 1 1 auto !important;
            flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    -webkit-flex-grow: 0 !important;
            flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    -webkit-flex-grow: 1 !important;
            flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    -webkit-flex-shrink: 0 !important;
            flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    -webkit-flex-shrink: 1 !important;
            flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important;
  }
  .justify-content-md-end {
    -webkit-justify-content: flex-end !important;
            justify-content: flex-end !important;
  }
  .justify-content-md-center {
    -webkit-justify-content: center !important;
            justify-content: center !important;
  }
  .justify-content-md-between {
    -webkit-justify-content: space-between !important;
            justify-content: space-between !important;
  }
  .justify-content-md-around {
    -webkit-justify-content: space-around !important;
            justify-content: space-around !important;
  }
  .align-items-md-start {
    -webkit-align-items: flex-start !important;
            align-items: flex-start !important;
  }
  .align-items-md-end {
    -webkit-align-items: flex-end !important;
            align-items: flex-end !important;
  }
  .align-items-md-center {
    -webkit-align-items: center !important;
            align-items: center !important;
  }
  .align-items-md-baseline {
    -webkit-align-items: baseline !important;
            align-items: baseline !important;
  }
  .align-items-md-stretch {
    -webkit-align-items: stretch !important;
            align-items: stretch !important;
  }
  .align-content-md-start {
    -webkit-align-content: flex-start !important;
            align-content: flex-start !important;
  }
  .align-content-md-end {
    -webkit-align-content: flex-end !important;
            align-content: flex-end !important;
  }
  .align-content-md-center {
    -webkit-align-content: center !important;
            align-content: center !important;
  }
  .align-content-md-between {
    -webkit-align-content: space-between !important;
            align-content: space-between !important;
  }
  .align-content-md-around {
    -webkit-align-content: space-around !important;
            align-content: space-around !important;
  }
  .align-content-md-stretch {
    -webkit-align-content: stretch !important;
            align-content: stretch !important;
  }
  .align-self-md-auto {
    -webkit-align-self: auto !important;
            align-self: auto !important;
  }
  .align-self-md-start {
    -webkit-align-self: flex-start !important;
            align-self: flex-start !important;
  }
  .align-self-md-end {
    -webkit-align-self: flex-end !important;
            align-self: flex-end !important;
  }
  .align-self-md-center {
    -webkit-align-self: center !important;
            align-self: center !important;
  }
  .align-self-md-baseline {
    -webkit-align-self: baseline !important;
            align-self: baseline !important;
  }
  .align-self-md-stretch {
    -webkit-align-self: stretch !important;
            align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .flex-lg-row {
    -webkit-flex-direction: row !important;
            flex-direction: row !important;
  }
  .flex-lg-column {
    -webkit-flex-direction: column !important;
            flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    -webkit-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    -webkit-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    -webkit-flex-wrap: wrap !important;
            flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    -webkit-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    -webkit-flex: 1 1 auto !important;
            flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    -webkit-flex-grow: 0 !important;
            flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    -webkit-flex-grow: 1 !important;
            flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    -webkit-flex-shrink: 0 !important;
            flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    -webkit-flex-shrink: 1 !important;
            flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    -webkit-justify-content: flex-end !important;
            justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    -webkit-justify-content: center !important;
            justify-content: center !important;
  }
  .justify-content-lg-between {
    -webkit-justify-content: space-between !important;
            justify-content: space-between !important;
  }
  .justify-content-lg-around {
    -webkit-justify-content: space-around !important;
            justify-content: space-around !important;
  }
  .align-items-lg-start {
    -webkit-align-items: flex-start !important;
            align-items: flex-start !important;
  }
  .align-items-lg-end {
    -webkit-align-items: flex-end !important;
            align-items: flex-end !important;
  }
  .align-items-lg-center {
    -webkit-align-items: center !important;
            align-items: center !important;
  }
  .align-items-lg-baseline {
    -webkit-align-items: baseline !important;
            align-items: baseline !important;
  }
  .align-items-lg-stretch {
    -webkit-align-items: stretch !important;
            align-items: stretch !important;
  }
  .align-content-lg-start {
    -webkit-align-content: flex-start !important;
            align-content: flex-start !important;
  }
  .align-content-lg-end {
    -webkit-align-content: flex-end !important;
            align-content: flex-end !important;
  }
  .align-content-lg-center {
    -webkit-align-content: center !important;
            align-content: center !important;
  }
  .align-content-lg-between {
    -webkit-align-content: space-between !important;
            align-content: space-between !important;
  }
  .align-content-lg-around {
    -webkit-align-content: space-around !important;
            align-content: space-around !important;
  }
  .align-content-lg-stretch {
    -webkit-align-content: stretch !important;
            align-content: stretch !important;
  }
  .align-self-lg-auto {
    -webkit-align-self: auto !important;
            align-self: auto !important;
  }
  .align-self-lg-start {
    -webkit-align-self: flex-start !important;
            align-self: flex-start !important;
  }
  .align-self-lg-end {
    -webkit-align-self: flex-end !important;
            align-self: flex-end !important;
  }
  .align-self-lg-center {
    -webkit-align-self: center !important;
            align-self: center !important;
  }
  .align-self-lg-baseline {
    -webkit-align-self: baseline !important;
            align-self: baseline !important;
  }
  .align-self-lg-stretch {
    -webkit-align-self: stretch !important;
            align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .flex-xl-row {
    -webkit-flex-direction: row !important;
            flex-direction: row !important;
  }
  .flex-xl-column {
    -webkit-flex-direction: column !important;
            flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    -webkit-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    -webkit-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    -webkit-flex-wrap: wrap !important;
            flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    -webkit-flex-wrap: nowrap !important;
            flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    -webkit-flex-wrap: wrap-reverse !important;
            flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    -webkit-flex: 1 1 auto !important;
            flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    -webkit-flex-grow: 0 !important;
            flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    -webkit-flex-grow: 1 !important;
            flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    -webkit-flex-shrink: 0 !important;
            flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    -webkit-flex-shrink: 1 !important;
            flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    -webkit-justify-content: flex-start !important;
            justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    -webkit-justify-content: flex-end !important;
            justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    -webkit-justify-content: center !important;
            justify-content: center !important;
  }
  .justify-content-xl-between {
    -webkit-justify-content: space-between !important;
            justify-content: space-between !important;
  }
  .justify-content-xl-around {
    -webkit-justify-content: space-around !important;
            justify-content: space-around !important;
  }
  .align-items-xl-start {
    -webkit-align-items: flex-start !important;
            align-items: flex-start !important;
  }
  .align-items-xl-end {
    -webkit-align-items: flex-end !important;
            align-items: flex-end !important;
  }
  .align-items-xl-center {
    -webkit-align-items: center !important;
            align-items: center !important;
  }
  .align-items-xl-baseline {
    -webkit-align-items: baseline !important;
            align-items: baseline !important;
  }
  .align-items-xl-stretch {
    -webkit-align-items: stretch !important;
            align-items: stretch !important;
  }
  .align-content-xl-start {
    -webkit-align-content: flex-start !important;
            align-content: flex-start !important;
  }
  .align-content-xl-end {
    -webkit-align-content: flex-end !important;
            align-content: flex-end !important;
  }
  .align-content-xl-center {
    -webkit-align-content: center !important;
            align-content: center !important;
  }
  .align-content-xl-between {
    -webkit-align-content: space-between !important;
            align-content: space-between !important;
  }
  .align-content-xl-around {
    -webkit-align-content: space-around !important;
            align-content: space-around !important;
  }
  .align-content-xl-stretch {
    -webkit-align-content: stretch !important;
            align-content: stretch !important;
  }
  .align-self-xl-auto {
    -webkit-align-self: auto !important;
            align-self: auto !important;
  }
  .align-self-xl-start {
    -webkit-align-self: flex-start !important;
            align-self: flex-start !important;
  }
  .align-self-xl-end {
    -webkit-align-self: flex-end !important;
            align-self: flex-end !important;
  }
  .align-self-xl-center {
    -webkit-align-self: center !important;
            align-self: center !important;
  }
  .align-self-xl-baseline {
    -webkit-align-self: baseline !important;
            align-self: baseline !important;
  }
  .align-self-xl-stretch {
    -webkit-align-self: stretch !important;
            align-self: stretch !important;
  }
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.25rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.5rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.5rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.mt-5,
.my-5 {
  margin-top: 3rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 3rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 3rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.25rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.25rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.5rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.5rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.5rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.5rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.pt-5,
.py-5 {
  padding-top: 3rem !important;
}

.pr-5,
.px-5 {
  padding-right: 3rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 3rem !important;
}

.pl-5,
.px-5 {
  padding-left: 3rem !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -3rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.25rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important;
  }
  .m-sm-2 {
    margin: 0.5rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important;
  }
  .m-sm-3 {
    margin: 1rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important;
  }
  .m-sm-4 {
    margin: 1.5rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important;
  }
  .m-sm-5 {
    margin: 3rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.25rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important;
  }
  .p-sm-2 {
    padding: 0.5rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important;
  }
  .p-sm-3 {
    padding: 1rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important;
  }
  .p-sm-4 {
    padding: 1.5rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important;
  }
  .p-sm-5 {
    padding: 3rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important;
  }
  .m-sm-n1 {
    margin: -0.25rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important;
  }
  .m-sm-n2 {
    margin: -0.5rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n3 {
    margin: -1rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important;
  }
  .m-sm-n4 {
    margin: -1.5rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n5 {
    margin: -3rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.25rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important;
  }
  .m-md-2 {
    margin: 0.5rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important;
  }
  .m-md-3 {
    margin: 1rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important;
  }
  .m-md-4 {
    margin: 1.5rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important;
  }
  .m-md-5 {
    margin: 3rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.25rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important;
  }
  .p-md-2 {
    padding: 0.5rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important;
  }
  .p-md-3 {
    padding: 1rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important;
  }
  .p-md-4 {
    padding: 1.5rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important;
  }
  .p-md-5 {
    padding: 3rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important;
  }
  .m-md-n1 {
    margin: -0.25rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important;
  }
  .m-md-n2 {
    margin: -0.5rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important;
  }
  .m-md-n3 {
    margin: -1rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important;
  }
  .m-md-n4 {
    margin: -1.5rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important;
  }
  .m-md-n5 {
    margin: -3rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.25rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important;
  }
  .m-lg-2 {
    margin: 0.5rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important;
  }
  .m-lg-3 {
    margin: 1rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important;
  }
  .m-lg-4 {
    margin: 1.5rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important;
  }
  .m-lg-5 {
    margin: 3rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.25rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important;
  }
  .p-lg-2 {
    padding: 0.5rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important;
  }
  .p-lg-3 {
    padding: 1rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important;
  }
  .p-lg-4 {
    padding: 1.5rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important;
  }
  .p-lg-5 {
    padding: 3rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important;
  }
  .m-lg-n1 {
    margin: -0.25rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important;
  }
  .m-lg-n2 {
    margin: -0.5rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n3 {
    margin: -1rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important;
  }
  .m-lg-n4 {
    margin: -1.5rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n5 {
    margin: -3rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.25rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important;
  }
  .m-xl-2 {
    margin: 0.5rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important;
  }
  .m-xl-3 {
    margin: 1rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important;
  }
  .m-xl-4 {
    margin: 1.5rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important;
  }
  .m-xl-5 {
    margin: 3rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.25rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important;
  }
  .p-xl-2 {
    padding: 0.5rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important;
  }
  .p-xl-3 {
    padding: 1rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important;
  }
  .p-xl-4 {
    padding: 1.5rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important;
  }
  .p-xl-5 {
    padding: 3rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important;
  }
  .m-xl-n1 {
    margin: -0.25rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important;
  }
  .m-xl-n2 {
    margin: -0.5rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n3 {
    margin: -1rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important;
  }
  .m-xl-n4 {
    margin: -1.5rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n5 {
    margin: -3rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
/*# sourceMappingURL=bootstrap-grid.css.map */
