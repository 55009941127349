.flag
{
  float: left;
  background-color: inherit;
  width: 25px;
}


@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .flag {
    width: 40px;
  }
}