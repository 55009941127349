@value sidebar-speed: .3s;
@value sidebar-animation: ease-in-out;

.sidebar {
  background-color: #F4F4F4;
  border-right: 2px solid #DFDFDF;
  min-height: calc(100vh - var(--header-height));
  width: var(--sidebar-width);
  order: 2;
  overflow-y: auto;
}

.collapsed {
  display: none;
}

@media screen and (max-width: 768px) {
  .sidebar {
    width: var(--sidebar-width-mobile);
    height: 0;
    min-height: none;
    position: fixed;
    top: var(--header-height-mobile);
    left: 0;
    bottom: 0;
    z-index: 1;
    flex: none;
    transition: transform sidebar-speed sidebar-animation;
    transform: translateX(var(---sidebar-width-mobile));
    -webkit-overflow-scrolling: touch;
  }
}
