.container {
  width: 100%;
  box-shadow: 2px 2px 4px rgba(0,0,0,.25);
  background-color: #F1F1F1;
  border: 1px solid hsl(0, 0%, 84%);
  padding: 1em;
}

.settingsContainer {
  margin-left: 2em;
  font-size: 0.9em;
}

.toggleContainer {
  display: inline-block;
  padding-right: 3em;
}

.optionsContainer {
  display: inline-block;
}

.optionLabel {
  margin-right: 1em;
  margin-left: 2em;
}

.checkbox {
  vertical-align: middle;
}

.input {
  margin-right: 2em;
  width: 8em;
}
