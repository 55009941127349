.section {
  width: 100%;
}

.line {
  margin: 0px;
  border: none;
  margin-bottom: 15px;
  margin-top: 5px;
  height: 1px;
  color: #E7E7E7; /* old IE */
  background-color: #E7E7E7; /* Modern Browsers */
}

.title {
  color: #5E5E5E;
  font-size: 24px;
  margin-bottom: 0px;
  font-weight: normal;
}

.icon {
  float: left;
  clear: left;
  margin-right: 8px;
  font-size: 19px;
  margin-top: -4px;
  color: #585858;
}