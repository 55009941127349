.svg {
  width: 150px;
  height: 150px;
}

.circleDiv {
  text-align: center;
}
#progressInput {
  margin: 20px auto;
  width: 30%;
}

.circleBackground,
.circleProgress {
  fill: none;
}

.circleBackground {
  stroke: #ddd;
}

.circleProgress {
  stroke: #117440;
  stroke-linecap: butt;
  stroke-linejoin: butt;
  stroke-width: 16;
}

.circleText {
  font-size: 3em;
  font-weight: bold;
  fill: #5F5F5F;
  color: #5F5F5F;
}

.circleText.smallCircleText {
  font-size: 2em;
}

.circleTextSubtitle {
  font-size: 1.5em;
  font-weight: bold;
  color: #5F5F5F;
}

.title {
  color:var(--vbop-main-colour);
  font-size: 1em;
}

@media (max-width: 768px) {
  .svg {
    width: 75px;
    height: 75px;
  }

  .title {
    font-size: 1em;
  }
}