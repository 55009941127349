.wrapper {
  margin-top: 60px;
}

.line {
  width: 100%;
  margin: 0px;
  border: none;
  height: 1px;
  /* Set the hr color */
  color: #D7D7D7; /* old IE */
  background-color: #D7D7D7; /* Modern Browsers */
}

.footer {
  padding: 20px 30px;
  color: #777777;
}

.link {
  font-size: x-small;
  font-weight: normal;
  color: #777777;
}

.link:hover {
  color: #777777;
}