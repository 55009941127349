.container {
  background-color: #F8F8F8;
  border: 1px solid #D8D8D8;
  border-radius: 0.3em;
  margin-right: 1em;
  padding: 0.2em;
  padding-left: 0.8em;
  padding-right: 0.8em;
  box-shadow: 0px 4px 4px rgba(211, 211, 211, 0.25);
  cursor: default;
}

.container:hover {
}

.text {
  margin-right: 1em;
  color: #898989
}

.dismissIcon {
  color: #E3E3E3;
  cursor: pointer;
}

.dismissIcon:hover {
  color: #B7B7B7;
}
