.container {
  width: 100%;
}

.node {
  list-style-type: none;
  padding: 0.2em;
  padding-right: 0.3em;
  padding-left: 2.0em;
}

.node:hover {
  border-radius: 0.2em;
  background-color: #E6E6E6;
  cursor: pointer;
}

.addNode {
  list-style-type: none;
  padding-right: 0.3em;
  padding-left: 3.3em;
}

.addNodeTextContainer {
  font-weight: bold;
  color: #B4B4B4;
  font-size: 1.2em;
  width: auto;
  display: inline-block;
  padding: 0.1em;
}

.addNodeTextContainer:hover {
  color: #0097EC;
  cursor: pointer;
}

.nodeSelected {
  border-radius: 0.2em;
  background-color: rgba(0, 151, 236, 0.37);
}

.caretContainer {
  width: 1.4em;
  display: inline-block;
}

.caret {
  font-size: 0.8em;
  color: #474747;
}

.title {

}

.removeIconContainer {
  float: right;
  width: 1.4em;
  height: 100%;
  text-align: center;
}

.removeIcon {
  color: white;
  margin: auto;
}

.nestedList {
  margin-left: 30px;
}
