.option {
  display: inline;
  margin-right: 2px;
  border: none;
  padding: 1px;
  opacity: 0.4;
}

.option:hover {
  opacity: 1;
}
