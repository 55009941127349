/* Themes */
.default {
  color: #545454;
}

.alternative {
  color: #FF9900;
}

.light {
  color: #B4B4B4;
}

.success {
  color: #7CB96D;
}

.error {
  color: red;
}

.black {
  color: black;
}

.white {
  color: white;
}

.faded {
  color: #C8C7C7;
}

/* Sizes */
.xxsmall {
  font-size: 8px;
}

.xsmall {
  font-size: 10px;
}

.small {
  font-size: 12px;
}

.medium {
  font-size: 14px;
}

.large {
  font-size: 16px;
}

.xlarge {
  font-size: 20px;
}

.xxlarge {
  font-size: 24px;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}
