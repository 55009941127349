.wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 10px 0px;
}

.buttetPoint {
  max-width: 13px;
  max-height: 13px;
  margin-top: 3px;
  margin-right: 10px;
}

.description {
  font-size: small;
}

.unavilable {
  font-weight: light;
  color: #ADADAD;
}

.title {
  font-size: small;
  font-weight: bold;
}

.infoWrapper {
  display: flex;
  flex-direction: column;
}