.default {
  display: inline-block;
  background-color: grey;
  color: white;
  width: 110px;
  font-size: 12px;
  padding: 3px 10px;
  font-family: 'Lato', sans-serif;
  text-align: left;
}

.draft {
  background-color: #559BC3;
}

.confirmed {
  background-color: #D4BC3E;
}

.processing {
  background-color: #B64BC8;
}

.completed {
  background-color: #55C360;
}
