.wrapper {
  width: 100%;
  min-height: 100px;
  padding: 10px;
  display: flex;
  margin: 5px;
}

.buttonWrapper {
  display: flex;
  margin-right: 15px;
  flex: 1;
  padding-top: 10px;
}

.button {
  background: #DD5C5C;
  color: #fff;
  border: none;

  width: 100px;
  height: 40px;
}

.infoWrapper {
  width: 100%;
  flex: 4;
}

.name {
  font-size: 12px;
  font-weight: bold;
}

.description {
  color: #999999;
}

.active {
  background: #66BD6E;
}