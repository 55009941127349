.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 1000px;
  padding: 40px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(81, 81, 81, 0.6);
}

.columnWrapper {
  display: flex;
  padding: 20px;
}

.column {
  flex: 1;
}

.input {
  width: 100%;
}

.verticalLine {
  width: 0px;
	border-left: 1px solid #C8C8C8;
  height: auto;
  margin: 10px 60px;
}

.replaceButtonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
