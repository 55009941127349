.container {
  background-color: #FF9191;
  padding: 8px 35px 8px 14px;
  margin-bottom: 20px;
  border-radius: 4px;
  background-repeat: repeat-x;
  font-size: small;
  line-height: 1.42857143;
  color: white;
}
