.containerBar {
  height: 10px;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.innerBar {
  float: left;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  text-align: center;
  background-color: #337ab7;
  box-shadow: inset 0 -1px 0 rgba(0,0,0,.15);
}

.blue {
  background-color: #337ab7;
}

.green {
  background-color: #55C360;
}
