.container {
  padding: 4em 3em;
  width: 25em;
  margin-right: 2em;
}

.dataList {
  width: 100%;
  overflow: hidden;
}

.dataList dt {
	clear: left;
  float: left;
  width: 40%;
  font-weight: bold;
  padding: 0.2em 0em;
}

.dataList dd {
	float: left;
  padding: 0.2em 0em;
}
