.default
{
  -webkit-border-radius: 2px;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  border: 1px solid #AAA;
  color: #555;
  border: 1px solid #ccc;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 30px;
  font-size: 12px;
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  font-weight: bold;
  padding: 5px 30px 5px 10px;
}
