.button {
  background-color: #78B2C5;
  padding: 0.1em;
  box-shadow: none;
  border: none;
}

.button:hover {
  background-color: #5D838F;
  box-shadow: none;
  border: none;
}

.image {
  font-family: 'Roboto', sans-serif;
}
