.th {
  padding: 6px 8px;
  text-align: left;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
}

.sortable {
  cursor: pointer;
}
