@value sidebar-backdrop-color: rgba(0,0,0,.5);
@value sidebar-backdrop-speed: .6s;

.wrapper {
  min-height: 100vh;
  display: flex;
  flex-flow: row wrap;
}

.content {
  width:calc(100% - var(--sidebar-width));
  min-height: calc(100vh - var(--header-height));
  flex: 1;
  order: 3;
  display: flex;
  flex-flow: column wrap;
  padding: 4em;
  padding-top: 2em;
  padding-bottom: 0em;
}

@media screen and (max-width: 768px) {
  .content {
    top: var(--header-height-mobile);
    position: relative;
    width: 100%;
    z-index: 1;
  }

  .content:before {
    width: 300vw;
    height: 100vh;
    display: block;
    right: 100vw;
    z-index: 1;
    box-shadow: 200vw 0 0 rgba(sidebar-backdrop-color, 0);
    transition: box-shadow sidebar-backdrop-speed linear;
    background:red;
  }

  .content:after {
    display: none;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
  }

  .scrollLock {
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
  }
}
